import { style } from "typestyle";
import { KSAColorTypes } from "../../../assets/colors";
import { DropdownMenuProps } from "./types";

export const DropdownMenu_Container = (props: DropdownMenuProps) =>
	style({
		visibility: props.visible ? "visible" : "hidden",
		position: "absolute",
		marginTop: props.topBarHeightRef,
		width: props.visible ? "100%" : 0,
		height: props.visible ? "100vh" : 0,
		backgroundColor: KSAColorTypes.BLACK,
		transition: "height 0.2s ease-in-out",
	});

export const DropdownMenu_ContentDiv = style({
	display: "flex",
	flexDirection: "column",
	rowGap: "12px",
	padding: "16px",
});
