import { style } from "typestyle";

export const ContactUs_Container = style({
	display: "flex",
	flexDirection: "column",
	width: "100%",
});

export const ContactUs_HeaderDiv = style({
	display: "flex",
	width: "100%",
	backgroundColor: "#00000060",
	padding: "128px 16px",
});

export const ContactUs_ContentDiv = style({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	rowGap: "32px",
	padding: "32px 16px",
	width: "100%",
});

export const ContactUs_EmailDiv = style({
	display: "flex",
	flexDirection: "row",
	columnGap: "8px",
	alignItems: "center",
});

export const ContactUs_SocialMediaButtonsDiv = style({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	columnGap: "12px",
});
