import { style } from "typestyle";

export const Global_Wrapper = (isDropdown: boolean) =>
	style({
		width: "100%",
		height: "100vh",
		backgroundColor: "black",
		display: "flex",
		flexDirection: "column",
		overflowX: "hidden",
		overflowY: isDropdown ? "hidden" : "auto",
	});

export const Global_ContentContainer = style({
		display: "flex",
		flexDirection: "column", 
		height: "100vh",
	});

export const Section_Container = (backgroundImage?: string) =>
	style({
		display: "flex",
		backgroundImage: backgroundImage && `url(${backgroundImage})`,
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
	});
