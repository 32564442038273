import { KSALogoProps } from "./types";
import Logo from "../../assets/images/logo/Logo.png";

export const KSALogo = (props: KSALogoProps) => {
	return (
		<img
			src={Logo}
			width={props.width ?? 50}
			height={props.height ?? 50}
			style={props.style}
			onClick={() => props.onClick && props.onClick()}
			alt="KSA_Logo"
		/>
	);
};
