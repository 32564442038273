import { useEffect } from "react";
import WithLoadingPageWrapper from "../../layout/WithLoadingPageWrapper";
import { IntroSection } from "./Intro_Section";
import { StartSection } from "./Start_Section";
import * as styles from "./styles";
import { HomeProps } from "./types";

const Home = (props: HomeProps) => {
	// Loading Effect
	// useEffect(() => {
	// 	props.onLoadingStart();
	// 	setTimeout(() => {
	// 		props.onLoadingEnd();
	// 	}, 1000);
	// }, []);

	return (
		<div className={styles.Home_Container}>
			<StartSection />
			<IntroSection />
		</div>
	);
};

export default WithLoadingPageWrapper(Home);
