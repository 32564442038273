import { style } from "typestyle";

export const LoadingIcon = style({
    display: "flex", 
    flex: 1
})

export const LoadingPageWrapper = (isLoading: boolean) =>
	style({
		display: isLoading ? "none" : "flex",
		flex: 1, 
	});
