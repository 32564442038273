import { style } from "typestyle";

export const KSATopBar_Container = style({
	display: "flex",
	position: "sticky",
	top: 0,
	width: "100%",
	backgroundColor: "#00000080",
	backdropFilter: "blur(12px)",
});

export const KSATopBar_ContentDiv = (isNotFull: boolean) => style({
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	justifyContent: "space-between",
	columnGap: "24px",
	width: "100%",
	padding: isNotFull ? "8px 16px" : "0 16px",
});
