import { FRwdModeHandler } from "@fantaskticedtechlimited/fui-complib";
import { RWDMode } from "@fantaskticedtechlimited/fui-complib/lib/esm/global.types";
import { FIcon, FIconTypes } from "@fantaskticedtechlimited/fui-iconlib";
import { KSAColorTypes } from "../../assets/colors";
import { KSAFontTypes } from "../../assets/fonts";
import {
	FacebookIcon,
	InstagramIcon,
	TwitterIcon,
	TelegramIcon,
	DiscordIcon,
	MediumIcon,
	BackgroundImage,
} from "../../assets/images";
import { EMAIL_ADDRESS, OfficialLinks } from "../../assets/links";
import WithLoadingPageWrapper from "../../layout/WithLoadingPageWrapper";
import { KSAText } from "../../lib/KSAText";
import * as styles from "./styles";
import * as globalStyles from "../../assets/styles/global.styles";
import { ContactUsProps } from "./types";

const ContactUs = (props: ContactUsProps) => {
	const rwdMode = FRwdModeHandler();
	const IconStyleProps: React.SVGAttributes<SVGSVGElement> = {
		fill: "white",
		width: rwdMode === RWDMode.MOBILE ? 24 : 32,
		height: rwdMode === RWDMode.MOBILE ? 24 : 32,
		cursor: "pointer",
	};
	// Loading Effect
	// useEffect(() => {
	// 	props.onLoadingStart();
	// 	setTimeout(() => {
	// 		props.onLoadingEnd();
	// 	}, 1000);
	// }, []);

	return (
		<div className={styles.ContactUs_Container}>
			<div className={globalStyles.Section_Container(BackgroundImage)}>
				<div className={styles.ContactUs_HeaderDiv}>
					<KSAText
						font={KSAFontTypes.Text({
							fontWeight: 800,
							fontSize: 32,
							lineHeight: "40px",
						})}
						color={KSAColorTypes.WHITE}
						children="Contact Us"
					/>
				</div>
			</div>
			<div className={globalStyles.Section_Container()}>
				<div className={styles.ContactUs_ContentDiv}>
					<KSAText
						font={KSAFontTypes.Text({
							fontWeight: 600,
							fontSize: 18,
							lineHeight: "24px",
						})}
						color={KSAColorTypes.WHITE}
						children="Email Us"
					/>
					<div className={styles.ContactUs_EmailDiv}>
						<FIcon name={FIconTypes.EMAIL} strokeColor={KSAColorTypes.WHITE} />
						<KSAText
							children={EMAIL_ADDRESS}
							font={KSAFontTypes.Text({
								fontSize: 16,
								lineHeight: "24px",
							})}
							color={KSAColorTypes.WHITE} 
						/>
					</div>
					<KSAText
						font={KSAFontTypes.Text({
							fontWeight: 600,
							fontSize: 18,
							lineHeight: "24px",
						})}
						color={KSAColorTypes.WHITE}
						children="Follow Us"
					/>
					<div className={styles.ContactUs_SocialMediaButtonsDiv}>
						<FacebookIcon
							{...IconStyleProps}
							onClick={() =>
								window.open(
									OfficialLinks.FACEBOOK,
									"_blank",
									"noopener,noreferrer"
								)
							}
						/>
						<InstagramIcon
							{...IconStyleProps}
							onClick={() =>
								window.open(
									OfficialLinks.INSTAGRAM,
									"_blank",
									"noopener,noreferrer"
								)
							}
						/>
						<TwitterIcon
							{...IconStyleProps}
							onClick={() =>
								window.open(
									OfficialLinks.TWITTER,
									"_blank",
									"noopener,noreferrer"
								)
							}
						/>
						<TelegramIcon
							{...IconStyleProps}
							onClick={() =>
								window.open(
									OfficialLinks.TELEGRAM,
									"_blank",
									"noopener,noreferrer"
								)
							}
						/>
						<DiscordIcon
							{...IconStyleProps}
							onClick={() =>
								window.open(
									OfficialLinks.DISCORD,
									"_blank",
									"noopener,noreferrer"
								)
							}
						/>
						<MediumIcon
							{...IconStyleProps}
							onClick={() =>
								window.open(
									OfficialLinks.MEDIUM,
									"_blank",
									"noopener,noreferrer"
								)
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WithLoadingPageWrapper(ContactUs);
