import { KSAFontProps, KSAFontFamilyTypes } from "./types";

export const handleKSAFontTypes = (
	props?: KSAFontProps,
	defaultProps?: KSAFontProps
) => {
	return (
		`${
			props?.fontWeight
				? typeof props?.fontWeight === "number"
					? props?.fontWeight.toString()
					: props?.fontWeight
				: defaultProps?.fontWeight
		}` +
		" " +
		`${
			props?.fontSize
				? typeof props?.fontSize === "number"
					? props?.fontSize / 16 + "rem"
					: props?.fontSize
				: defaultProps?.fontSize
		}` +
		"/" +
		`${
			props?.lineHeight
				? props?.lineHeight
				: defaultProps?.lineHeight
		}` +
		" " +
		KSAFontFamilyTypes.Quicksand
	);
};
