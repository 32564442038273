import { KSAColorTypes } from "../../assets/colors";
import { KSAText } from "../../lib/KSAText";
import * as styles from "./styles";
import { TopBarButtonList } from "../../lib/KSATopBar/TopBarButtonList";
import { FooterProps } from "./types";
import { KSAFontTypes } from "../../assets/fonts";

export const Footer = (props: FooterProps) => {
	return (
		<div className={styles.Footer_Container}>
			<div className={styles.Footer_ContentDiv}>
				<TopBarButtonList routes={props.menuRoutes} autoExpand isFooter />
				<KSAText
					children="Copyright © 2022 kingsarmy.io All Rights Reserved"
					color={KSAColorTypes.WHITE}
					font={KSAFontTypes.Text({ fontSize: 12 })}
				/>
			</div>
		</div>
	);
};
