import { style } from "typestyle";
import { KSAColorTypes } from "../../../assets/colors";
import { TopBarButtonListProps } from "./types";

export const MenuListDiv = (props: TopBarButtonListProps) =>
	style({
		display: "flex",
		flexDirection: props.isDropdownMenu ? "column" : "row",
		flexGrow: props.autoExpand ? 1 : 0,
		columnGap: props.isDropdownMenu ? 0 : "8px",
		rowGap: props.isDropdownMenu ? "24px" : 0,
		alignItems: props.isDropdownMenu ? "flex-start" : "center",
	});

export const SubMenuList = (isIncluded: boolean, depth: number, isDropdownMenu: boolean) =>
	style({
		width: isDropdownMenu ? "100%" : "fit-content",
		paddingLeft: `${12 * depth}`,
		display: isIncluded || depth === 0 ? "visible" : "none",
	});

export const LinkButtonDiv = (isSelected: boolean) =>
	style({
		borderRadius: 0,
		backgroundColor: "transparent !important",
		padding: "8px 12px !important",
		$nest: {
			"&:hover": {
				$nest: {
					div: {
						color: isSelected
							? undefined
							: KSAColorTypes.YELLOW + " !important",
					},
				},
			},
		},
	});
