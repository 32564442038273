import { style } from "typestyle";
import { KSAColorTypes } from "../../assets/colors";

export const NotFound_Container = style({
	display: "flex",
	flexDirection: "column", 
	height: "100%",
	width: "100%",
	justifyContent: "center",
	alignItems: "center",
	backgroundColor: KSAColorTypes.WHITE+"D9",
});
