import { FRwdModeHandler } from "@fantaskticedtechlimited/fui-complib";
import { RWDMode } from "@fantaskticedtechlimited/fui-complib/lib/esm/global.types";
import { useEffect, useRef } from "react";
import * as styles from "./styles";
import { KSATopBarProps } from "./types";

export const KSATopBar = (props: KSATopBarProps) => {
	const rwdMode = FRwdModeHandler();
	const TopBarRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (TopBarRef.current) props.topBarRef(TopBarRef.current.offsetHeight);
	}, [TopBarRef.current]);

	return (
		<div className={styles.KSATopBar_Container} ref={TopBarRef}>
			<div className={styles.KSATopBar_ContentDiv(rwdMode !== RWDMode.FULL)}>
				{props.children}
			</div>
		</div>
	);
};
