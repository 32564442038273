export enum KSAColorTypes {
	BLACK = "#202020",
	DARK = "#505176",
	GREY = "#B0B6BB",
	LIGHT = "#E2E2E2",
	WHITE = "#FFFFFF",
	RED = "#F95141",
	ORANGE = "#FFB131",
	YELLOW = "#FFCD33",
	GREEN = "#2ED47A",
	BLUE = "#109CF1",
	PURPLE = "#885AF8",
	BRAND = "#3246FF",
	BRAND_BG = "#F1F3FF",
}
