import { style } from "typestyle";
import { KSAColorTypes } from "../../assets/colors";
import { KSAFontTypes } from "../../assets/fonts";
import { KSATextProps } from "./types";

export const KSAText_Container = (props: KSATextProps) =>
	style({
		color: props.color ?? KSAColorTypes.BLACK,
		font: props.font ?? KSAFontTypes.Text(),
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "pre-wrap",
		wordBreak: "break-word",
		display: props.maxRows && props.maxRows > 0 ? "-webkit-box" : "block",
		"-webkit-line-clamp":
			props.maxRows && props.maxRows > 0 ? props.maxRows : undefined,
		WebkitBoxOrient: "vertical",
	});
