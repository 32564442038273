import { Fragment, useEffect, useState } from "react";
import * as globalStyles from "./assets/styles/global.styles";
import "./assets/styles/global.styles.css";
import { BrowserRouter as Router } from "react-router-dom";
import { PageRoutesRender } from "./layout/PageRoutesRender";
import { AfterAuthRoutes, MainMenuRoutes } from "./route";
import { TopNavBar } from "./components/TopNavBar";
import { Footer } from "./components/Footer";
import { FScrollBarStyle } from "@fantaskticedtechlimited/fui-complib";

const KingsArmyLandingPage = () => {
	const [isDropdownMenu, setIsDropdownMenu] = useState<boolean>(false);

	useEffect(() => {
		if (isDropdownMenu) document.body.style.overflow = "hidden";
		else document.body.style.overflow = "auto";
	}, [isDropdownMenu]);

	return (
		<Fragment>
			<Router>
				<div
					className={
						globalStyles.Global_Wrapper(isDropdownMenu) +
						" " +
						FScrollBarStyle({ visible: true, width: 8 })
					}
				>
					<TopNavBar
						menuRoutes={MainMenuRoutes}
						onDropdownMenuOpen={setIsDropdownMenu}
					/>
					<div className={globalStyles.Global_ContentContainer}>
						<PageRoutesRender
							routes={AfterAuthRoutes}
							redirectPathIfNotExist="/notFound"
						/>
						<Footer menuRoutes={MainMenuRoutes} />
					</div>
				</div>
			</Router>
		</Fragment>
	);
};

export default KingsArmyLandingPage;
