import { style } from "typestyle";
import { KSAColorTypes } from "../../../assets/colors";

export const Start_Section_ContentDiv = (isMobile: boolean) =>
	style({
		display: "flex",
		flexDirection: isMobile ? "column" : "row",
		alignItems: "center",
		columnGap: isMobile ? 0 : "48px",
		rowGap: isMobile ? "40px" : 0,
		maxWidth: "1280px",
		margin: "0 auto",
		backgroundColor: "#00000060",
		padding: "32px 16px",
	});

export const Start_Section_GreetingDiv = (isMobile: boolean) =>
	style({
		display: "flex",
		flexDirection: "column",
		rowGap: isMobile ? "40px" : "64px",
		alignItems: "center",
	});

export const Start_Section_GreetingDiv_MainButtonsDiv = style({
	width: "100%",
	display: "flex",
	flexDirection: "row",
	columnGap: "12px",
	justifyContent: "flex-start",
	alignItems: "flex-start",
});

export const PlayNow_ButtonDiv = (isMobile: boolean) =>
	style({
		backgroundColor: "#00000060 !important",
		padding: (isMobile ? "4px 8px" : "4px 12px") + " !important",
		border: "2px solid " + KSAColorTypes.YELLOW + " !important",
		$nest: {
			"&:hover": {
				opacity: 0.7,
			},
		},
	});

export const PlayNow_PopUp_ContentDiv = (isMobile: boolean) =>
	style({
		display: "flex",
		flexDirection: "column",
		rowGap: isMobile ? "16px" : "48px",
		alignItems: "center",
	});

export const Start_Section_GreetingDiv_SocialMediaButtonsDiv = style({
	width: "100%",
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	columnGap: "12px",
});
