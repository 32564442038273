import { Fragment } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { RouteConfig } from "../../utils/useRouteService/types";
import { PageRoutesRenderProps } from "./types";

const renderRoute = (routes: RouteConfig[]) => {
	return routes.map((r, i) => {
		return (
			<Route
				{...(r as RouteConfig)} // for exact, path ... settings
				key={r.key || i}
				element={<r.component route={r} />}
			>
				{Array.isArray(r.routes) && renderRoute(r.routes)}
			</Route>
		);
	});
};
export function PageRoutesRender(props: PageRoutesRenderProps): JSX.Element {
	return (
		<Routes>
			<Fragment>
				{renderRoute(props.routes)}
				<Route
					path={"*"}
					element={<Navigate to={props.redirectPathIfNotExist ?? "/"} />}
				/>
			</Fragment>
		</Routes>
	);
}
