import { style } from "typestyle";

export const Intro_Section_ContentDiv = (isMobile: boolean) =>
	style({
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		rowGap: isMobile ? "24px" : "40px",
		maxWidth: "1280px",
		margin: "0 auto",
		padding: "32px 16px",
	});
