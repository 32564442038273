import {
	FButton,
	FPopUp,
	FRwdModeHandler,
} from "@fantaskticedtechlimited/fui-complib";
import { FButtonTypes } from "@fantaskticedtechlimited/fui-complib/lib/esm/FButton/types";
import { RWDMode } from "@fantaskticedtechlimited/fui-complib/lib/esm/global.types";
import { useState } from "react";
import { KSAColorTypes } from "../../../assets/colors";
import { KSAFontTypes } from "../../../assets/fonts";
import { KSALogo } from "../../../lib/KSALogo";
import { KSAText } from "../../../lib/KSAText";
import * as styles from "./styles";
import * as globalStyles from "../../../assets/styles/global.styles";
import { OfficialLinks } from "../../../assets/links";
import {
	FacebookIcon,
	InstagramIcon,
	TwitterIcon,
	TelegramIcon,
	DiscordIcon,
	MediumIcon,
	BackgroundImage,
} from "../../../assets/images";

export const StartSection = () => {
	const [openPopUp, setOpenPopUp] = useState<boolean>(false);
	const rwdMode = FRwdModeHandler();
	const IconStyleProps: React.SVGAttributes<SVGSVGElement> = {
		fill: "white",
		width: rwdMode === RWDMode.MOBILE ? 24 : 32,
		height: rwdMode === RWDMode.MOBILE ? 24 : 32,
		cursor: "pointer",
	};
	return (
		<div className={globalStyles.Section_Container(BackgroundImage)}>
			<div
				className={styles.Start_Section_ContentDiv(rwdMode === RWDMode.MOBILE)}
			>
				{rwdMode === RWDMode.MOBILE && <KSALogo width={250} height={250} />}
				<div
					className={styles.Start_Section_GreetingDiv(
						rwdMode === RWDMode.MOBILE
					)}
				>
					<KSAText
						font={KSAFontTypes.Text({
							fontWeight: 600,
							fontSize: rwdMode === RWDMode.MOBILE ? 24 : 56,
							lineHeight: rwdMode === RWDMode.MOBILE ? "36px" : "80px",
						})}
						color={KSAColorTypes.WHITE}
						style={{
							textAlign: "left",
						}}
						children="No kings shall sleep while others have loot to take"
					/>
					<div className={styles.Start_Section_GreetingDiv_MainButtonsDiv}>
						<FButton
							type={FButtonTypes.OUTLINE}
							label="Play Now"
							className={styles.PlayNow_ButtonDiv(rwdMode === RWDMode.MOBILE)}
							labelProps={{
								font: KSAFontTypes.Text({
									fontWeight: 700,
									fontSize: rwdMode === RWDMode.MOBILE ? 16 : 24,
									lineHeight: rwdMode === RWDMode.MOBILE ? "24px" : "36px",
								}),
								color: KSAColorTypes.WHITE,
							}}
							onClick={() => setOpenPopUp(true)}
						/>
					</div>
					<div
						className={styles.Start_Section_GreetingDiv_SocialMediaButtonsDiv}
					>
						<KSAText
							font={KSAFontTypes.Text({
								fontWeight: 300,
								fontSize: 12,
								lineHeight: "16px",
							})}
							color={KSAColorTypes.WHITE}
							children="Follow Us"
						/>
						<FacebookIcon
							{...IconStyleProps}
							onClick={() =>
								window.open(
									OfficialLinks.FACEBOOK,
									"_blank",
									"noopener,noreferrer"
								)
							}
						/>
						<InstagramIcon
							{...IconStyleProps}
							onClick={() =>
								window.open(
									OfficialLinks.INSTAGRAM,
									"_blank",
									"noopener,noreferrer"
								)
							}
						/>
						<TwitterIcon
							{...IconStyleProps}
							onClick={() =>
								window.open(
									OfficialLinks.TWITTER,
									"_blank",
									"noopener,noreferrer"
								)
							}
						/>
						<TelegramIcon
							{...IconStyleProps}
							onClick={() =>
								window.open(
									OfficialLinks.TELEGRAM,
									"_blank",
									"noopener,noreferrer"
								)
							}
						/>
						<DiscordIcon
							{...IconStyleProps}
							onClick={() =>
								window.open(
									OfficialLinks.DISCORD,
									"_blank",
									"noopener,noreferrer"
								)
							}
						/>
						<MediumIcon
							{...IconStyleProps}
							onClick={() =>
								window.open(
									OfficialLinks.MEDIUM,
									"_blank",
									"noopener,noreferrer"
								)
							}
						/>
					</div>
				</div>
				{rwdMode !== RWDMode.MOBILE && (
					<KSALogo
						width={rwdMode === RWDMode.TABLET ? 320 : 400}
						height={rwdMode === RWDMode.TABLET ? 320 : 400}
					/>
				)}
			</div>
			{openPopUp && (
				<FPopUp
					popUpName=""
					onClose={() => setOpenPopUp(false)}
					style={{
						padding: rwdMode === RWDMode.MOBILE ? "1rem" : undefined,
						minWidth: rwdMode === RWDMode.MOBILE ? "fit-content" : undefined,
					}}
					overlayStyle={{
						backgroundColor: "#00000080",
					}}
				>
					<div
						className={styles.PlayNow_PopUp_ContentDiv(
							rwdMode === RWDMode.MOBILE
						)}
					>
						<KSAText
							font={KSAFontTypes.Text({
								fontSize: rwdMode === RWDMode.MOBILE ? 16 : 20,
								lineHeight: rwdMode === RWDMode.MOBILE ? "24px" : "32px",
							})}
							children="The game is coming soon."
						/>
						<FButton
							label="Close"
							labelProps={{
								font: KSAFontTypes.Text({
									fontSize: 14,
									lineHeight: "20px",
									fontWeight: 700,
								}),
							}}
							style={{ padding: 0 }}
							onClick={() => setOpenPopUp(false)}
						/>
					</div>
				</FPopUp>
			)}
		</div>
	);
};
