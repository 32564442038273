import * as styles from "./styles";
import { KSATextProps } from "./types";

export const KSAText = (props: KSATextProps) => {
	return (
		<div
			className={props.className + " " + styles.KSAText_Container(props)}
			style={props.style}
			children={props.children ?? "Text"}
		/>
	);
};
