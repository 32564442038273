import { FButton, FLinkButton } from "@fantaskticedtechlimited/fui-complib";
import { Fragment } from "react";
import { useLocation } from "react-router-dom";
import { KSAColorTypes } from "../../../assets/colors";
import { KSAFontTypes } from "../../../assets/fonts";
import { useRouteService } from "../../../utils/useRouteService";
import { RouteConfig } from "../../../utils/useRouteService/types";
import * as styles from "./styles";
import { TopBarButtonListProps } from "./types";

export const TopBarButtonList = (props: TopBarButtonListProps) => {
	const location = useLocation();
	const routeService = useRouteService();
	const checkIsSelected = (
		locationPath: string,
		itemPath: string | string[] | undefined
	) => {
		if (!itemPath) {
			return false;
		}
		if (Array.isArray(itemPath)) {
			return itemPath.includes(locationPath);
		} else {
			return locationPath === itemPath;
		}
	};

	const checkIsIncluded = (
		locationPath: string,
		itemPath: string | string[] | undefined
	) => {
		if (!itemPath) {
			return false;
		}
		if (Array.isArray(itemPath)) {
			let isInclude = false;
			itemPath.forEach((e) => {
				if (locationPath.includes(e)) {
					isInclude = true;
					return;
				}
			});
			return isInclude;
		} else {
			return locationPath.includes(itemPath);
		}
	};

	const RenderTopBarButtonList = (
		pathName: string,
		routes: RouteConfig[],
		depth: number,
		isParentPathIncluded: boolean,
		parentPath: string
	) => {
		return (
			<Fragment>
				{routes.map((r, i) => {
					const haveSubSubMenus = Array.isArray(r.routes);
					const newParentPath =
						depth === 0 ? r.path : `${parentPath}/${r.path}`;
					const isSelected = checkIsSelected(pathName, newParentPath);
					const isIncluded = checkIsIncluded(pathName, newParentPath);
					if (r.hideInMenu) return null;
					if (r.externalLink) {
						return (
							<Fragment key={i}>
								<FButton
									label={r.menuName}
									style={{
										width: props.isDropdownMenu ? "100%" : "fit-content",
										justifyContent: "flex-start"
									}}
									className={styles.LinkButtonDiv(isSelected)}
									labelStyle={{
										font: KSAFontTypes.Text({
											fontSize: props.isFooter
												? 10
												: props.isDropdownMenu
												? 18
												: 14,
											lineHeight: props.isFooter
												? "14px"
												: props.isDropdownMenu
												? "30px"
												: "24px",
										}),
										color:
											isSelected && !props.isFooter
												? KSAColorTypes.YELLOW
												: KSAColorTypes.WHITE,
									}}
									onClick={() => {
										window.open(
											r.externalLink,
											"_blank",
											"noopener,noreferrer"
										);
										props.isDropdownMenu && props.onClose && props.onClose();
									}}
								/>
							</Fragment>
						);
					}
					return (
						<Fragment key={i}>
							<div
								className={styles.SubMenuList(
									isParentPathIncluded,
									depth,
									props.isDropdownMenu!
								)}
							>
								<FLinkButton
									key={`${depth}_${i}`}
									containsRouteChildren={haveSubSubMenus}
									actionIcon={{
										strokeColor: isSelected
											? KSAColorTypes.BRAND
											: KSAColorTypes.WHITE,
									}}
									pathIsSelected={isSelected}
									label={r.menuName}
									pathLink={
										r.defaultRedirect ??
										routeService.extractPathLink(newParentPath)
									}
									className={styles.LinkButtonDiv(isSelected)}
									labelStyle={{
										font: KSAFontTypes.Text({
											fontSize: props.isFooter
												? 10
												: props.isDropdownMenu
												? 18
												: 14,
											lineHeight: props.isFooter
												? "14px"
												: props.isDropdownMenu
												? "30px"
												: "24px",
										}),
										color:
											isSelected && !props.isFooter
												? KSAColorTypes.YELLOW
												: KSAColorTypes.WHITE,
									}}
									onClick={() =>
										props.isDropdownMenu && props.onClose && props.onClose()
									}
								/>
							</div>
							{haveSubSubMenus &&
								RenderTopBarButtonList(
									pathName,
									r.routes!,
									depth + 1,
									isIncluded,
									newParentPath || ""
								)}
						</Fragment>
					);
				})}
			</Fragment>
		);
	};
	return (
		<div className={styles.MenuListDiv(props)}>
			{RenderTopBarButtonList(location.pathname, props.routes, 0, false, "")}
		</div>
	);
};
