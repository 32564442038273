import { KSAColorTypes } from "../../../assets/colors";
import { KSAFontTypes } from "../../../assets/fonts";
import { KSAText } from "../../../lib/KSAText";
import * as styles from "./styles";
import * as globalStyles from "../../../assets/styles/global.styles"; 
import { RWDMode } from "@fantaskticedtechlimited/fui-complib/lib/esm/global.types";
import { FRwdModeHandler } from "@fantaskticedtechlimited/fui-complib";

export const IntroSection = () => {
	const rwdMode = FRwdModeHandler();
	return (
		<div className={globalStyles.Section_Container()}>
			<div
				className={styles.Intro_Section_ContentDiv(rwdMode === RWDMode.MOBILE)}
			>
				<KSAText
					font={KSAFontTypes.Text({
						fontWeight: 800,
						fontSize: 32,
						lineHeight: "40px",
					})}
					color={KSAColorTypes.YELLOW}
					children={"What the hell is King's Army ?"}
				/>
				<KSAText
					font={KSAFontTypes.Text({
						fontWeight: 600,
						fontSize: 16,
						lineHeight: "24px",
					})}
					color={KSAColorTypes.GREY}
					style={{
						textAlign: "center", 
					}}
					children={
						"King's Army is a strategy war play-to-earn NFT game designed to provide the user to collect the various generals, equipment & pets to conquer the lands by exploring the lands and clashing against other kingdoms in search for $COINS. This in-game currency will allow the respective kingdom to expand their army to conquer the lands that are beyond their knowledge.\n" +
						"\n\nThe game is deployed on the Binance Smart Chain and is compatible with web3 wallets such as MetaMask, which will be used to accept transactions and pay for gas fees. MetaMask can be installed as a browser extension, or as an application on your mobile devices."
					}
				/>
				
			</div>
		</div>
	);
};
