export enum OfficialLinks {
    // WHITEPAPER="https://kingsarmy.gitbook.io/whitepaper/",
	WHITEPAPER = "https://whitepaper.kingsarmy.io/",	//go public
	INSTAGRAM = "https://www.instagram.com/k.i.n.g.s_a.r.m.y/",
	TWITTER = "https://twitter.com/KingsArmy14",
	DISCORD = "http://discord.gg/qTzbUNwsYZ",
	FACEBOOK = "https://www.facebook.com/Kings-Army-107722078488950/",
	TELEGRAM = "https://t.me/KingsArmyEng",
	MEDIUM = "https://kingsarmy.medium.com/",
}

export const EMAIL_ADDRESS = "info@kingsarmy.io"
