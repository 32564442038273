import WebFont from "webfontloader";
import { handleKSAFontTypes } from "./func";

export enum KSAFontFamilyTypes {
	Quicksand = "Quicksand",
}

const FontFamilyList = [KSAFontFamilyTypes.Quicksand];

WebFont.load({
	google: {
		families: FontFamilyList,
	},
});

export interface KSAFontProps {
	fontWeight?: number | string;
	fontSize?: string | number;
	lineHeight?: string;
	fontFamily?: KSAFontFamilyTypes | string
}

export interface KSAFontTypes {
	TEXT: KSAFontProps
}
const defaultKSAFontTypes: KSAFontTypes = {
	TEXT: {
		fontWeight: "400",
		fontSize: "1rem",
		fontFamily: KSAFontFamilyTypes.Quicksand,
		lineHeight: "24px",
	},
};

export namespace KSAFontTypes {
	export const Text = (props?: KSAFontProps) => {
		return handleKSAFontTypes(props, defaultKSAFontTypes.TEXT) as string;
	};
}
