import { OfficialLinks } from "./assets/links";
import ContactUs from "./pages/ContactUs";
import Home from "./pages/Home";
import { NotFound } from "./pages/NotFound";
import { RouteConfig } from "./utils/useRouteService/types";

export const BeforeAuthRoutes: RouteConfig[] = [
	// {
	// 	path: "/login",
	// 	component: Login,
	// 	exact: false,
	// 	menuName: "Login",
	// 	breadcrumbName: "Login",
	// 	hideInMenu: true,
	// 	routes:[
	// 		{
	// 			component: LoginHome,
	// 			breadcrumbName: "LoginHome",
	// 			exact: true,
	// 			hideInMenu: true,
	// 			index:true
	// 		},{
	// 			path:"sentMagicLink",
	// 			component:SentEmailMagicLink,
	// 			breadcrumbName:"Sent Magic Link"
	// 		},{
	// 			path:"verifyEmailMagicLink",
	// 			component:VerifyEmailMagicLink,
	// 			breadcrumbName:"Verify Magic Link"
	// 		}
	// 	]
	// },
];

export const MainMenuRoutes: RouteConfig[] = [
	{
		path: "/",
		component: Home,
		menuName: "Home",
		breadcrumbName: "Home",
		exact: true,
	},
	{
		component: ContactUs,
		menuName: "Whitepaper",
		breadcrumbName: "Whitepaper",
		externalLink:OfficialLinks.WHITEPAPER,
	},
	{
		path: "/contactUs",
		component: ContactUs,
		menuName: "ContactUs",
		breadcrumbName: "ContactUs",
		exact: true,
	},
	{
		path: "/notFound",
		component: NotFound,
		menuName: "NotFound",
		breadcrumbName: "NotFound",
		exact: true,
		hideInMenu: true,
	},
];

export const FooterMenuRoutes: RouteConfig[] = [
	// {
	// 	path: "/settings",
	// 	component: Setting,
	// 	menuName: "Settings",
	// 	breadcrumbName: "Settings",
	// 	exact: true,
	// },
];

export const AfterAuthRoutes = [...MainMenuRoutes, ...FooterMenuRoutes];
