import {
	FButton,
	FRwdModeHandler,
	FSVGIcon,
} from "@fantaskticedtechlimited/fui-complib";
import { useNavigate } from "react-router-dom";
import { KSAColorTypes } from "../../assets/colors";
import { KSAFontTypes } from "../../assets/fonts";
import { KSAText } from "../../lib/KSAText";
import { KSATopBar } from "../../lib/KSATopBar";
import { TopBarButtonList } from "../../lib/KSATopBar/TopBarButtonList";
import { TopNavBarProps } from "./types";
import { ReactComponent as Menu } from "../../assets/images/topNavBar/Menu.svg";
import { useEffect, useState } from "react";
import { RWDMode } from "@fantaskticedtechlimited/fui-complib/lib/esm/global.types";
import { DropdownMenu } from "./DropdownMenu";
import { FIcon, FIconTypes } from "@fantaskticedtechlimited/fui-iconlib";

export const TopNavBar = (props: TopNavBarProps) => {
	const navigate = useNavigate();
	const rwdMode = FRwdModeHandler();
	const [openDropdownMenu, setOpenDropdownMenu] = useState<boolean>(false);
	const [topBarHeight, setTopBarHeight] = useState<number | undefined>(0);

	useEffect(() => {
		if (openDropdownMenu) {
			props.onDropdownMenuOpen(true);
		} else {
			props.onDropdownMenuOpen(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openDropdownMenu]);

	return (
		<>
			<KSATopBar topBarRef={setTopBarHeight}>
				<FButton
					style={{
						backgroundColor: "transparent",
						borderRadius: 0,
						padding: 0,
					}}
					onClick={() => navigate("/")}
				>
					<KSAText
						font={KSAFontTypes.Text({
							fontWeight: 800,
							fontSize: 20,
						})}
						style={{
							textShadow: KSAColorTypes.WHITE + " 0 0 0.25rem",
						}}
						color={KSAColorTypes.WHITE}
						children="KING'S ARMY"
					/>
				</FButton>
				{rwdMode === RWDMode.FULL && (
					<TopBarButtonList routes={props.menuRoutes} autoExpand />
				)}
				{rwdMode !== RWDMode.FULL ? (
					openDropdownMenu ? (
						<FIcon
							name={FIconTypes.CLOSE}
							strokeColor={KSAColorTypes.WHITE}
							onClick={() => setOpenDropdownMenu(false)}
							containerStyle={{
								alignSelf: "flex-end",
							}}
						/>
					) : (
						<FSVGIcon onClick={() => setOpenDropdownMenu(true)}>
							<Menu stroke={KSAColorTypes.WHITE} width={24} height={24} />
						</FSVGIcon>
					)
				) : undefined}
			</KSATopBar>
			{rwdMode !== RWDMode.FULL && (
				<DropdownMenu
					visible={openDropdownMenu}
					topBarHeightRef={topBarHeight}
					onClose={() => setOpenDropdownMenu(false)}
					routes={props.menuRoutes}
				/>
			)}
		</>
	);
};
