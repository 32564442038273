import { style } from "typestyle";
import { KSAColorTypes } from "../../assets/colors";

export const Footer_Container = style({
	display: "flex",
	width: "100%",  
	bottom: 0, 
	backgroundColor: KSAColorTypes.BLACK + "80",
});

export const Footer_ContentDiv = style({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	rowGap: "24px",
	width: "inherit",
	margin: "0 auto",
	maxWidth: "1200px",
	padding: "16px",
});

export const Footer_SocialMediaDiv = style({
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	columnGap: "24px",
});

export const SocialMediaButtonsDiv = style({
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	columnGap: "12px",
});
