import { KSAFontTypes } from "../../assets/fonts";
import { KSAText } from "../../lib/KSAText";
import * as styles from "./styles";

export const NotFound = () => {
	return (
		<div className={styles.NotFound_Container}>
			<KSAText
				font={KSAFontTypes.Text({
					fontSize: 24,
					lineHeight: "32px",
					fontWeight: 600,
				})}
				children={`ERROR: 404.\nSorry. This page is not found.`}
			/>
		</div>
	);
};
