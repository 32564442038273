import * as styles from "./styles";
import { DropdownMenuProps } from "./types";
import { TopBarButtonList } from "../../../lib/KSATopBar/TopBarButtonList";
export const DropdownMenu = (props: DropdownMenuProps) => {
	return (
		<div className={styles.DropdownMenu_Container(props)}>
			<div className={styles.DropdownMenu_ContentDiv}>
				<TopBarButtonList
					routes={props.routes}
					autoExpand
					isDropdownMenu
					onClose={() => props.onClose()}
				/>
			</div>
		</div>
	);
};
